var keyStr = "ABCDEFGHIJKLMNOP" + "QRSTUVWXYZabcdef" + "ghijklmnopqrstuv" +
	"wxyz0123456789+/" + "=";

function tzc_encode64(input) {
	var output = "";
	var chr1, chr2, chr3 = "";
	var enc1, enc2, enc3, enc4 = "";
	var i = 0;
	do {
		chr1 = input.charCodeAt(i++);
		chr2 = input.charCodeAt(i++);
		chr3 = input.charCodeAt(i++);
		enc1 = chr1 >> 2;
		enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
		enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
		enc4 = chr3 & 63;
		if (isNaN(chr2)) {
			enc3 = enc4 = 64;
		} else if (isNaN(chr3)) {
			enc4 = 64;
		}
		output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2) +
			keyStr.charAt(enc3) + keyStr.charAt(enc4);
		chr1 = chr2 = chr3 = "";
		enc1 = enc2 = enc3 = enc4 = "";
	} while (i < input.length);

	return output;
}

export function jn_encode64(input) {
	return tzc_encode64(input);
	//return my_encode64(input);
}
export function testaa(input) {

	return "testaa" + input;
}
export function ischeck(input) {
	if (input == "1") {
		return "√";
	} else {
		return "×";
		//return "<font color=red>×</font>";
	}
}

export function ischeck_class(aa) {
	if (aa == 1) {
		return "green";
	} else if (aa == 2) {
		return "blue";
	} else if (aa == 3) {
		return "purple";
	} if (aa == 0) {
		return "purple";
	} else {
		return "#108ee9";
	}
}

export function isnull_class(aa) {
	if (aa && aa != "") {
		return "#2db7f5";
	} else {
		return "";
	}
}

export function isnull_style(aa) {
	if (aa && aa != "") {
		return "";
	} else {
		return "display:none;";
	}
}
function S4() {
	return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}
export function myguid() {
	return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}


//将秒数转换为时分秒格式
export function formatSeconds(value) {
	var theTime = parseInt(value);// 秒
	var middle = 0;// 分
	var hour = 0;// 小时

	if (theTime > 60) {
		middle = parseInt(theTime / 60);
		theTime = parseInt(theTime % 60);
		if (middle > 60) {
			hour = parseInt(middle / 60);
			middle = parseInt(middle % 60);
		}
	}
	var result = "" + parseInt(theTime) + "秒";
	if (middle > 0) {
		result = "" + parseInt(middle) + "分" + result;
	}
	if (hour > 0) {
		result = "" + parseInt(hour) + "小时" + result;
	}
	return result;
}

export function course_class(aa) {
	if (aa == 'polyv') {
		return "jn_softdj_iconfont jn_softdj_iconbofang_huaban";
	} else if (aa == 'polyv_yp') {
		return "jn_softdj_iconfont jn_softdj_iconbofang_huaban";
	} else if (aa == 'tuwen') {
		return "jn_softdj_iconfont jn_softdj_icontuwentag";
	} else {
		return "jn_softdj_iconfont jn_softdj_iconbofang_huaban";
	}
}


export function course_xueshi(aa) {
	var a1 = aa / 2700;
	return a1.toFixed(2);
}

export function pb_toxuexiao() {
	console.log("跳转小程序登录页面..............");
	window.wx.miniProgram.reLaunch({ url: '/pages/xuexiao/index' })
}

export default {
	jn_encode64,
	testaa,
	ischeck,
	ischeck_class, isnull_style, isnull_class, myguid, formatSeconds, course_class, course_xueshi, pb_toxuexiao
};
