import axios from '../http' // 导入http中创建的axios实例
import Qs from 'qs' // 根据需求是否导入qs模块
import jn_encode642 from './jn_tool.js'  //加密账号密码 （加密参数）
import { resquest } from './base'
// ........登录相关接口........
//验证码
export function get_url_code() {
    // return `${resquest}/CheckCode3?bgc=FFF&bc=e4e4e4&h=40&w=120&x=10&isgrx=1`;
    return `${resquest}/api/pub/CheckCode3?bgc=FFF&bc=e4e4e4&h=40&w=120&x=10&isgrx=1`;
}
//登录方法（市管局、企业）
export function login_do(sdata) {
    var data = Qs.stringify({
        "username": jn_encode642.jn_encode64(sdata.username),
        "password": jn_encode642.jn_encode64(sdata.password),
        "verifycode": jn_encode642.jn_encode64(sdata.verifycode),
        "login_type": jn_encode642.jn_encode64(sdata.login_type),
        local_guid: sdata.local_guid
    });
    return axios.post(`${resquest}/api/pub/scjg/login_do`, data);
}
//登录检测方法（企业） /api/pub/scjg/check_login_do
export function check_com_login_do(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/scjg/check_login_do`, data2);
}
// 市管局
// 获取登录个人信息 /api​/user​/scuser_man_info​/getmyuser  /api/user/scuser_man_info/getmyuser
export function getmyuser(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_man_info/getmyuser`, data2)
}
//密码修改
export function passwd_edit(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_man_info/passwd_edit`, data2)
}
// 企业
// 获取登录个人信息 /api​/user​/scuser_man_info​/getmyuser  /api/user/scuser_man_info/getmyuser
export function getmyuser2(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_com_info/getmyuser`, data2)
}
//密码修改
export function passwd_edit2(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_com_info/passwd_edit`, data2)
}
//获取个人信息
export function getuserinfo2(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_com_info/getuserinfo`, data2)
}
//登录后企业摄像头数量
export function cam_list(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_com_cam/cam_list`, data2)
}

export function cam_count(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/user/scuser_com_cam/cam_count`, data2)
}

export function getlocal(params) {
    var data2 = Qs.stringify(params);
    return axios.post(`${resquest}/api/pub/getlocal`, data2)
}

