<template>
  <div class="hu_notice_box">
      <van-nav-bar
      title="修改密码"
      left-text="返回"
      left-arrow
      fixed
      placeholder
      @click-left="$router.go(-1)"
    >
    </van-nav-bar>
    <div class="hu_notice_list">
        <van-form @submit="onSubmit">
            <van-cell-group inset>
                <van-field
                v-model="my_oldpasswd"
                type="password"
                name="my_oldpasswd"
                label="旧密码"
                placeholder="旧密码"
                />
                <van-field
                v-model="my_newpasswd"
                type="password"
                name="my_newpasswd"
                label="新密码"
                placeholder="新密码"
                />
                <van-field
                v-model="my_newpasswd2"
                type="password"
                name="my_newpasswd2"
                label="重复新密码"
                placeholder="重复新密码"
                />
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">
                提交
                </van-button>
            </div>
        </van-form>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Toast } from 'vant'
import { passwd_edit2 } from "@/api/J_Sign";
export default {
    setup(props) {
        const my_oldpasswd = ref('');
        const my_newpasswd = ref('');
        const my_newpasswd2 = ref('');
        let routers = useRouter()
        const onSubmit = (values) => {
            passwd_edit2({...values}).then((res) => {
                if (res.data.statusCode=='200') {
                    Toast.success(res.data.message);
                    routers.go(-1);
                } else{
                    Toast.fail(res.data.message);
                }
                console.log('submit',...values)
                // console.log('cg',res.data.statusCode)
            }).catch((err) => {
               console.log(err) 
            });
        
        };
        onMounted(()=>{
            // onLoad();
        });
        return{
            my_oldpasswd,
            my_newpasswd,
            my_newpasswd2,
            onSubmit
        }
    }
}
</script>

<style scoped lang="less">
//头部样式
/deep/ .van-nav-bar__placeholder{
  z-index: 99999;
}
/deep/ .van-nav-bar__title{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
/deep/ .van-icon-arrow-left{
  color: #323233;
}
/deep/ .van-nav-bar__text{
  color: #323233;
}
.hu_notice_list{
    margin-top: 0.5rem;
    padding: 0 0.5rem;
}
</style>